import './PageNotFound.css'

function PageNotFound() {
    return (
        <div className={'page-not-found-div'}>
            <h1>Page not found. Please return to <a href={'https://thetourneyhub.com'}>thetourneyhub.com</a></h1>
            <p>Who sent you here?!?!</p>
        </div>
    )
}

export default PageNotFound;