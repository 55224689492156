export enum Months{
    JANUARY,
    FEBRUARY,
    MARCH,
    APRIL,
    MAY,
    JUNE,
    JULY,
    AUGUST,
    SEPTEMBER,
    OCTOBER,
    NOVEMBER,
    DECEMBER
}

export enum Days{
    Sun,
    Mon,
    Tue,
    Wed,
    Thu,
    Fri,
    Sat
}